import { LDClient as LDServerClient, LDContext, LDFlagSet } from '@launchdarkly/node-server-sdk'
import { LDClient, LDFlagValue } from 'launchdarkly-js-client-sdk'
import { createContext, useContext } from 'react'

import { captureError } from '../error-reporting.js'
import { isServer } from '../is-server.js'

type FeatureFlagContext = {
  flags: LDFlagSet
  context?: LDContext
  client?: LDClient
}

const reportEvaluation = async (context: LDContext, flagKey: string, defaultValue: LDFlagValue) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- we need to access the global object
  const serverClient = (global as any).launchDarklyClient as LDServerClient | undefined
  if (!serverClient) {
    captureError(new Error('LaunchDarkly client not initialized while reporting evaluation'))
    return
  }
  await serverClient.variation(flagKey, context, defaultValue)
}

export const featureFlaggingContext = createContext<FeatureFlagContext>({ flags: {}, context: undefined })

export const useFeatureFlag = (flagKey: string | undefined, defaultValue: LDFlagValue) => {
  const context = useContext(featureFlaggingContext)

  if (!flagKey) {
    return defaultValue
  }

  if (!context.context) {
    return defaultValue
  }

  if (isServer) {
    reportEvaluation(context.context, flagKey, defaultValue)
    return context.flags[flagKey] ?? defaultValue
  }

  if (!context.client) {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- clients unexpectedly are missing these properties
    return context.flags?.[flagKey] ?? defaultValue
  }

  return context.client.variation(flagKey, defaultValue)
}
